import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  employee: [],
  state: "idle",
  error: null,
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_EMPLOYEE:
        return {
            ...state,
            employee: Array.isArray(action.payload) ? action.payload : [],
          };
        //   case actionTypes.CHECK_CATEGORY_SLUG:
        //     return {
        //       ...state,
        //       success: action.payload,
        //       error: null,
        //     };
        //     case actionTypes.GET_CATEGORY:
        //       return {
        //         ...state,
        //         category: action.payload,
        //       };
        
    default:
      return state;
  }
};

export default employeeReducer;
